import React, { Component } from "react";
import { hotjar } from "react-hotjar";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link } from "react-router-dom";
class Register extends Component {
  componentDidMount() {
    hotjar.initialize("2739117", 6);
  }
  state = {
    email: "",
    password: "",
    name:"",
    lastname:"",
    confirmPassword: "",
    postId: "",
    termsOfUse:false,
    emailValid: true,
    passwordValid: true,
    passwordsSame: true,
    confirmEmail: false,
    requestGotError: false,
    requestErrorMessage: "",
    token: "",
    nameValid: true,
    lastNameValid: true,
    termsOfUseValid: true
  };

  changeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  toggleChange = () => {
    this.setState((state) => ( {isChecked: !state.isChecked}));
  }
  validateForm = () => {
    const {
      email,
      password,
      confirmPassword,
      name,
      lastname,
    } = this.state;

    var emailValidTemp = true;
    var passwordValidTemp = true;
    var passwordsSameTemp = true;
    var nameValidTemp = true;
    var lastNameValidTemp = true;
    var termsOfUseTemp = true;

    //Reset validating flags
    this.setState({
      emailValid: true,
      passwordValid: true,
      passwordsSame: true,
      nameValid: true,
      lastNameValid: true,
      termsOfUseValid: true
    });

    if (!this.validateEmail(email)) {
      emailValidTemp = false;
      this.setState({ emailValid: false });
    }

    if (password.length < 6) {
      passwordValidTemp = false;
      this.setState({ passwordValid: false });
    }

    if (password !== confirmPassword) {
      passwordsSameTemp = false;
      this.setState({ passwordsSame: false });
    }

    if (name.replace(/\s+/g, '').length == 0) {
      nameValidTemp = false;
      this.setState({ nameValid: false });
    }

    if(lastname.replace(/\s+/g, '').length == 0){
      lastNameValidTemp = false;
      this.setState({ lastNameValid: false });
    }
    if(!this.state.isChecked){
      termsOfUseTemp = false;
      this.setState({ termsOfUseValid: false });
    }
    if (!emailValidTemp || !passwordValidTemp || !passwordsSameTemp || !nameValidTemp || !lastNameValidTemp||!termsOfUseTemp)
      return false;

    return true;
  };

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  onVerify = (token) => {
    this.setState({ token: token });
  };

  addUser = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { email, password, token, name, lastname } = this.state;

    if (!this.validateForm(this.state)){
      this.setState({ loading: false });
      return;
    } 

    var postData = new URLSearchParams();
    postData.append("userName", email);
    postData.append("password", password);
    postData.append("token", token);
    postData.append("name", name);
    postData.append("lastname", lastname);
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      // mode: 'no-cors',
      body: postData.toString(),
    };

    //debugger;

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/User/CreateUser`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        //debugger;
        if (data.isSucces === true) {
          localStorage.setItem('verifyEmail',email);
          this.setState({
            email: "",
            password: "",
            confirmPassword: "",
            emailValid: true,
            passwordValid: true,
            passwordsSame: true,
            confirmEmail: true,
            requestGotError: false,
            requestErrorMessage: "",
            loading: false
          });
          window.location.href = "/Welcome";
        } else {
          this.setState({
            confirmEmail: false,
            requestGotError: true,
            requestErrorMessage: data.message,
            loading: false
          });
        }
      });
  };

  

  render() {

    const {
      email,
      name,
      lastname,
      password,
      confirmPassword,
      emailValid,
      passwordValid,
      passwordsSame,
      confirmEmail,
      requestGotError,
      requestErrorMessage,
      loading,
      nameValid,
      lastNameValid,
      termsOfUseValid
    } = this.state;
    return (
      <div className="container mx-auto px-4 h-full">
        <GoogleReCaptcha
          onVerify={this.onVerify}
        />
        <div className="">
          <div className="">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div class="mb-0 px-6 py-6">
                  <div className="text-blueGray-400 text-center mb-3 font-bold">
                    <large>Sign up now</large>
                  </div>
                </div>
                <form onSubmit={this.addUser}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      // type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={this.changeInput}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      First Name
                    </label>
                    <input
                      // type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="First Name"
                      name="name"
                      id="name"
                      value={name}
                      onChange={this.changeInput}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="lastname"
                    >
                      Last Name
                    </label>
                    <input
                      // type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Last Name"
                      name="lastname"
                      id="lastname"
                      value={lastname}
                      onChange={this.changeInput}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={this.changeInput}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="confirmPassword"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={this.changeInput}
                    />
                  </div>

                  <div className="relative w-full mb-3">             
                    <input
                      type="checkbox"
                      className="" 
                      name="termsOfUse"
                      id="termsOfUse"
                      defaultChecked={this.state.isChecked}
                      onChange={this.toggleChange}
                    />
                   <span> I Agree to terms :
                   <Link to="/terms" style={{color: "blue"}}> Terms of Use</Link>
                    </span>               
                  </div>
                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={emailValid ? { display: "none" } : { color: "red" }}
                  >
                    Email is invalid.
                  </div>

                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={
                      passwordValid ? { display: "none" } : { color: "red" }
                    }
                  >
                    Password must be at least 6 characters long.
                  </div>

                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={
                      passwordsSame ? { display: "none" } : { color: "red" }
                    }
                  >
                    Passwords must be the same.
                  </div>

                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={
                      nameValid ? { display: "none" } : { color: "red" }
                    }
                  >
                    First name missing. Please provide your first name.
                  </div>

                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={
                      lastNameValid ? { display: "none" } : { color: "red" }
                    }
                  >
                    Last name missing. Please provide your last name.
                  </div>
                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={
                      termsOfUseValid ? { display: "none" } : { color: "red" }
                    }
                  >
                    The terms must be accepted.
                  </div>
                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={
                      confirmEmail ? { color: "green" } : { display: "none" }
                    }
                  >
                    Thank you for subscribing check your email address and
                    finish creating your account.
                  </div>

                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={
                      requestGotError ? { color: "red" } : { display: "none" }
                    }
                  >
                    {requestErrorMessage === "Username already exists." ? (
                      <p1>
                        You already have an account.{" "}
                        <Link to="/login" style={{color: "blue", textDecoration: "underline"}}>Sign in here</Link>
                      </p1>
                    ) : (
                      <p1>Something went wrong. {requestErrorMessage}</p1>
                    )}
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      {loading ? "Loading" : "Create Account"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
